.board {
    $breakpoint: 580px;

    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 10px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.show {
        opacity: 1;
    }

    &--level-1 {
        grid-template-columns: repeat(3, 1fr);

        @media screen and (min-width: $breakpoint) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &--level-2 {
        grid-template-columns: repeat(4, 1fr);

        @media screen and (min-width: $breakpoint) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &--level-3 {
        grid-template-columns: repeat(6, 1fr);

        @media screen and (min-width: $breakpoint) {
            grid-template-columns: repeat(8, 1fr);
        }
    }

    &--level-4 {
        grid-template-columns: repeat(8, 1fr);

        @media screen and (min-width: $breakpoint) {
            grid-template-columns: repeat(12, 1fr);
        }
    }

}

.card {
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    color: #ccc;
    cursor: pointer;
    padding-bottom: 100%;
    position: relative;

    &.is-match {
        color: #fff;
        background-color: #99ce2a;
        animation: match 0.5s ease-in-out;
        cursor: default;
    }

    >svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: auto !important;
        height: auto !important;

    }
}

@keyframes match {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.08);
    }

    60% {
        transform: scale(1.0);
    }

    80% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}