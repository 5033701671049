@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oswald:wght@200&display=swap');

*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: #555358;
}

h1,
h2,
h3 {
    font-family: Lobster, Georgia, sans-serif;
}

h1 {
    font-size: 8vw;
    margin: 0 0 .75em;

    @media screen and (min-width: 800px) {
        font-size: 4rem;
    }
}

h3 {
    font-size: 5vw;

    @media screen and (min-width: 800px) {
        font-size: 2.5rem;
    }
}

.container {
    max-width: 800px;
    width: 95%;
    margin: 0 auto;
}

.text-center {
    text-align: center;
}

.app-title {
    border-bottom: 2px dotted #555358;
    padding: .5em 0;
}