/**
 * Level Selector
 * -------------------------------------------- */
.levels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Oswald', Arial, Helvetica, sans-serif;
    font-weight: 200;
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;

    >li {
        margin: 0 15px 30px;
        padding: 5px;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #f6f6f6;
        border-radius: 5px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
            color: #fff;
            background-color: #99ce2a;
            transform: scale(1.04);
        }
    }
}